<template>
  <div class="auth-wrapper auth-v1 align-start py-0">
    <div class="auth-inner my-auto">
      <v-card-text>
        <v-row class="px-0 py-1 mx-0 my-5">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <div class="d-flex justify-space-between align-center">
              <v-img
                :src="appLogoTitle"
                max-width="150px"
                alt="logo"
                contain
                class="me-3 cursor-pointer"
                @click="$router.push('/')"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title class="d-flex justify-center white--text text-h5">
        {{ $t('deletion_request.deletion_request_text') }}
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="verifySubmit"
        >
          <v-row class="mt-3 mx-0 px-4 py-1">
            <v-col
              cols="12"
              class="py-1 px-2 mt-4 d-flex justify-center align-center"
            >
              <span
                class="white--text"
              >
                {{ t('deletion_request.were_sorry' ) }}
              </span>
            </v-col>
          </v-row>

          <v-row class="mb-5 px-4 py-1 ma-0">
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-center align-center"
            >
              <ul class="white--text mt-3">
                <li class="mb-2">
                  {{ t('deletion_request.no_longer_record' ) }}
                </li>
                <li class="mb-2">
                  {{ t('deletion_request.no_email_sms' ) }}
                </li>
                <li class="mb-2">
                  {{ t('deletion_request.once_account_deletion_process' ) }}
                </li>
              </ul>
            </v-col>
          </v-row>

          <v-row
            v-if="userData"
            class="mb-5 px-4 py-1 ma-0"
          >
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-center align-center"
            >
              <span
                class="white--text text-body-1"
              >
                {{ userData.username }}
              </span>
            </v-col>
          </v-row>

          <v-row class="my-5 px-4 py-1 ma-0">
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-center align-center"
            >
              <v-btn
                class="text-capitalize"
                style="height: 42px !important"
                color="secondary"
                dark
                rounded
                block
                type="submit"
              >
                <span v-if="userData">
                  {{ t('deletion_request.delete_account') }}
                </span>
                <span v-else>
                  {{ t('tooltip.continue') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </div>

    <v-dialog
      v-model="isOpenConfirm"
      width="400px"
    >
      <v-card class="py-5">
        <v-icon
          class="absolute-close"
          @click="() => isOpenConfirm = false"
        >
          {{ icons.mdiClose }}
        </v-icon>

        <v-card-text class="pt-4 pb-0  d-flex justify-center align-center">
          <v-icon
            color="warning"
            size="80"
          >
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-card-text>

        <v-card-text class="pt-4 pb-0">
          <v-card-title class="text-center pb-0 px-8">
            {{ t('deletion_request.are_you_sure') }}
          </v-card-title>
        </v-card-text>

        <v-card-text>
          <v-row class="mt-5 px-0 py-1 ma-0">
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-center align-center"
            >
              <v-btn
                class="text-xs"
                style="text-transform: inherit;"
                color="secondary"
                dark
                rounded
                block
                @click="onDelete"
              >
                {{ t('tooltip.continue') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isOpen"
      width="400px"
    >
      <v-card class="py-5">
        <v-icon
          class="absolute-close"
          @click="() => isOpen = false"
        >
          {{ icons.mdiClose }}
        </v-icon>

        <v-card-text class="pt-4 pb-0  d-flex justify-center align-center">
          <v-icon
            color="success"
            size="80"
          >
            {{ icons.mdiCheckCircleOutline }}
          </v-icon>
        </v-card-text>

        <v-card-text class="pt-4 pb-0">
          <v-card-title class="justify-center pb-0">
            {{ t('deletion_request.received_your_request') }}
          </v-card-title>
          <v-card-subtitle class="justify-center text-center pb-0 mt-2">
            {{ t('deletion_request.confirmation_email') }}
          </v-card-subtitle>
        </v-card-text>

        <v-card-text>
          <v-row class="mt-5 px-0 py-1 ma-0">
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-center align-center"
            >
              <v-btn
                class="text-xs"
                style="text-transform: inherit;"
                color="secondary"
                dark
                rounded
                block
                @click="$router.replace('/')"
              >
                {{ t('error_404.back_home') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { useUtils } from '@core/libs/i18n'
import themeConfig from '@themeConfig'
import {
  mdiClose,
  mdiAccountGroup,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiCheckCircleOutline,
  mdiHelpCircleOutline,
} from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import usePhone from '@core/utils/usePhone'
import parsePhoneNumber from 'libphonenumber-js'
import useCountry from '@core/utils/useCountry'
import useLogData from '@core/utils/useLogData'
import useCryptoJs from '@core/utils/useCryptoJs'
import { useRouter } from '@core/utils'
import { deleteUserAccount } from '@api'
import store from '@/store'

export default {
  setup() {
    const { t } = useUtils()
    const { phoneCodeOptions, initPhoneConfig } = usePhone()
    const { getCountryCode } = useCountry()
    const { logData, currentLogData, updateLogData } = useLogData()
    const { userData } = useCryptoJs()
    const { router } = useRouter()

    const circle = ref({
      circle_id: null,
      circle_logo: null,
      circle_name: null,
    })
    const circleId = ref(null)
    const refCode = ref(null)
    const hasException = ref(false)
    const invalidInvitation = ref(false)
    const isOpen = ref(false)
    const isOpenConfirm = ref(false)

    const form = ref(null)
    const phoneInput = ref(null)
    const initCode = ref('')
    const isPhoneVisible = ref(false)

    const code = ref(null)
    const phoneCode = ref(null)
    const fullPhone = ref(null)

    const onDelete = async () => {
      isOpenConfirm.value = false
      if (userData.value) {
        await deleteUserAccount()
        isOpen.value = true
        store.dispatch('auth/logoutAction')
      }
    }

    const verifySubmit = async () => {
      if (userData.value) {
        isOpenConfirm.value = true
      } else {
        logData.value.joinGame = 'NO'
        logData.value.matchCode = null
        logData.value.redirect = 'deletion-request'
        logData.value.field = 'all'
        logData.value.date = 'all'
        logData.value.time = 'all'
        await updateLogData(logData.value)
        router.push({ name: 'auth-login-phone-email' })
      }
    }

    const onClearCode = () => {
      isPhoneVisible.value = false
    }

    const validatePhone = e => {
      if (!/^([0-9])*$/.test(e)) {
        isPhoneVisible.value = false

        return 'Invalid number'
      }

      if (phoneCode.value && e) {
        const phoneNumberJS = parsePhoneNumber(e, phoneCode.value)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            fullPhone.value = phoneNumberJS.number

            return true
          }
          fullPhone.value = null
        }
      } else if (!code.value.includes('+')) {
        onClearCode()
      }

      return 'Invalid number'
    }

    const onInputCode = e => {
      if (!isPhoneVisible.value && e && e !== '' && e.length) {
        if (e.length > 1) {
          if (/^\+?([0-9])+$/.test(e)) {
            code.value = code.value.replace('+', '')
            phoneCode.value = initCode.value
            isPhoneVisible.value = true
          }
        }
      }
    }

    onMounted(async () => {
      Object.assign(logData.value, currentLogData.value)
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }

      isPhoneVisible.value = false

      // setTimeout(() => {
      //   if (userData.value) phoneInput.value.focus()
      // }, 500)
    })

    return {
      circle,
      circleId,
      refCode,
      hasException,
      invalidInvitation,
      isOpen,
      isOpenConfirm,

      userData,
      form,
      phoneInput,
      initCode,
      isPhoneVisible,
      code,
      phoneCode,
      phoneCodeOptions,
      logData,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appLogoTitle: themeConfig.app.logoTitle,

      verifySubmit,
      validatePhone,
      initPhoneConfig,
      onInputCode,
      onClearCode,

      onDelete,
      avatarText,

      t,

      validators: {
        required,
        emailValidator,
      },

      icons: {
        mdiClose,
        mdiAccountGroup,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCheckCircleOutline,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/preset/preset/pages/view.scss';
  @import '@core/preset/preset/pages/landing.scss';
  </style>
  <style lang="scss" scoped>
    .absolute-close {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #222222;
      color: #ffffff6b;
      border-radius: 50px !important;
      padding: 2px;
    }

    .border-img {
      border-radius: 10px;
    }

    .text-mail {
      color: #FF3232;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.01875rem;
      text-decoration: none;
      white-space: nowrap;
    }
  </style>
